import React from 'react';
import './Menu.css';
import { slide as Menu } from 'react-burger-menu'


class GameMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          menuOpen: false
        }
    }

    handleStateChange (state) {
      this.setState({menuOpen: state.isOpen})  
    }

    closeMenu = () => {
      this.setState({menuOpen: false})
    }

    requestHint = () => {
      this.closeMenu();
      this.props.requestHint();
    }
    
    showHcpInfo = () => {
      this.closeMenu();
      var info = this.lang("Det är inte nödvändigt att gå upp för trappan för att lösa pusslet, tillräcklig information finns exempelvis vid ingången till FIKA!", "It is not neccessary to take the stairs to solve this puzzle, enough information can be found by the entrance to FIKA! for example.");
      this.props.showHcpInfo(info);
    }

    showBusinessCard = () => {
      this.closeMenu();
      var info = <img alt="Visitkort" className="img-fluid" src={"images/visitkort" + this.lang("", "-EN") + ".jpg"}></img>
      this.props.showHcpInfo(info);
    }

    createInfoLinkList = () => {
      return (
        this.props.infoLinks.map((item, index) => {
          return this.props.currentStep === item.step && <a key={index} className="menu-item" target="blank" href={item.url}>{item.title}</a>
        })
      )
    }

    hasInfoLink = () => {
      return (
        this.props.infoLinks.filter(x => x.step === this.props.currentStep).length > 0
      )
    }

    lang = (sv, en) => {
      return this.props.lang === 'sv' ? sv : en;
    }

    render () {
        
      return (
        <Menu isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)} 
          right 
          width={ 250 }
          disableAutoFocus>
          {/* Visa hints */}
          {this.props.hintEnabled && (
            <a onClick={ this.requestHint } className="menu-item">{ this.props.lastHint ? this.lang("Lösning", "Solution") : this.lang("Ledtråd", "Hint") }</a>
          )}
          {/* Visa Visitkort */}
          {/* {this.props.currentStep === 7 && (
            <a onClick={ this.showBusinessCard } className="menu-item">{ this.lang("Tappat visitkortet?", "Lost the business card?") }</a>
          )} */}

          {/* Visa hcp symbol */}
          {/* { (this.props.currentStep === 3 || (this.props.currentStep === 2 && !this.props.hintEnabled )) && (
            <a onClick={ this.showHcpInfo } className="menu-item" title="Funktionsnedsättning"><FontAwesomeIcon icon={faWheelchair} /> <FontAwesomeIcon icon={faBabyCarriage} /></a>
          )} */}

          {/* Om-länk */}
          <a className="menu-item" href={"https://www.stadsspel.se/"} title="Läs mer om Molinders Stadsspel" target="blank">{this.lang("Om", "About")}</a>

          <span className="menu-header">{this.hasInfoLink() === true ? this.lang("Läs mer om", "Read more about") : ""}</span>
            { this.createInfoLinkList() }
        </Menu>
      );
    }

}

export default GameMenu;