import React, { Component } from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import './InnerImageZoomStyles.min.css';
import OnImagesLoaded from 'react-on-images-loaded';

class Message extends Component {
   

    constructor(props) {
        super(props);

        this.state = {
            alignment: this.props.data.direction === 2 ? "align-right" : "",
            messageType: this.props.data.direction === 2 ? "other-message" : `my-message${this.props.data.chatNo}`
        }
        
    }

    lang = (sv, en) => {
        return this.props.lang === 'sv' ? sv : en;
    }

    fromLang = () => {
        if(this.props.data.direction === 1)
        {
            if(this.props.data.status === 4 && this.props.data.isSolution === 1){
                return this.lang("Lösning", "Solution");
            }
            if(this.props.data.status === 4){
                return this.lang("Ledtråd", "Hint");
            }
            if (this.props.data.chatNo === 1)
            {
                return "Per Silja";
            }
            else
            {
                return "Urho Klokkonen";
            }            
        }
        else
        {
            return this.lang("Laget", "Team");
        }
    }
  
    render() {
      return (
            <li className="clearfix">
                <div className={`message-data ${this.state.alignment}`}>
                    <span className="message-data-time" >{this.props.data.date}</span> &nbsp; &nbsp;
                    <span className="message-data-name" >{this.fromLang()}</span> <i className="fa fa-circle me"></i>
                </div>
                <div className={`message ${this.state.messageType}`}>
                    {this.props.data.image && this.props.data.image.indexOf("youtube") === -1 && <div>
                        <OnImagesLoaded
                         onLoaded={this.props.onImgLoad.bind(this)}>
                            <InnerImageZoom src={`images/${this.props.data.image}`} />
                        </OnImagesLoaded>
                    </div>}
                    {this.props.data.image && this.props.data.image.indexOf("youtube") > 0 && <div>
                        <iframe width="100%" height="200" src={this.props.data.image} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>}
                    
                    {this.props.data.message}
                </div>
                
            </li>
      );
    }
  }

  export default Message;